import { reactNode } from '@shared/node-v1.0.0'

import v200 from '@nodes/anchor-v2.0.0'

export default reactNode(
	'Anchor',
	{
		'v2.0.0': v200,
	},
	{ docs: '' }
)
